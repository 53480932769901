import React from 'react';
import {
  Link,
  Stack,
  useBreakpoint,
  Button,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  Tooltip,
  Box,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

import { ProtectedContent } from './ProtectedContent';

import { Role } from '@/types';

export function Nav() {
  const breakpoint = useBreakpoint();
  const { data } = useSession();
  const isMobile = ['base', 'sm'].includes(breakpoint);

  const router = useRouter();
  const isMasquerading = router.pathname === '/admin/quote/[id]';

  return isMobile ? (
    <Menu>
      <MenuButton as={Button} variant="outline" colorScheme="lightPurple" ml="auto">
        =
      </MenuButton>

      <MenuList width="full">
        <ProtectedContent requiredRole={Role.ADMIN}>
          <NextLink href="/admin/patients" passHref legacyBehavior>
            <MenuItem>
              <Link>Patients</Link>
            </MenuItem>
          </NextLink>
          <NextLink href="/admin/quotes" passHref legacyBehavior>
            <MenuItem>
              <Link>Quotes</Link>
            </MenuItem>
          </NextLink>
          <NextLink href="/admin/services" passHref legacyBehavior>
            <MenuItem>
              <Link>Services</Link>
            </MenuItem>
          </NextLink>
          <NextLink href="/admin/locations" passHref legacyBehavior>
            <MenuItem>
              <Link>Locations</Link>
            </MenuItem>
          </NextLink>

          {/* <MenuItem>
            <NextLink href="/invoices">
              <Link href="/invoices">Invoices</Link>
            </NextLink>
          </MenuItem>

          <MenuItem>
            <NextLink href="/payments">
              <Link href="/payments">Payments</Link>
            </NextLink>
          </MenuItem> */}
        </ProtectedContent>
        <MenuItem onClick={() => signOut()}>
          <Link>Logout</Link>
        </MenuItem>
      </MenuList>
    </Menu>
  ) : (
    <Stack
      as="nav"
      direction="row"
      ml="auto"
      alignItems="center"
      fontSize="md"
      spacing={[1, 1, 2, 8]}
    >
      <ProtectedContent requiredRole={Role.ADMIN}>
        <NextLink href="/admin/patients" legacyBehavior>
          <Tooltip
            hasArrow
            label="View and search all patients, create and update quotes"
            textAlign="center"
          >
            <Link href="/admin/patients" fontSize="lg">
              Patients
            </Link>
          </Tooltip>
        </NextLink>
        <NextLink href="/admin/quotes" legacyBehavior>
          <Tooltip hasArrow label="View and search all quotes." textAlign="center">
            <Link href="/admin/quotes" fontSize="lg">
              Quotes
            </Link>
          </Tooltip>
        </NextLink>
        <NextLink href="/admin/services" legacyBehavior>
          <Tooltip hasArrow label="View and search all services" textAlign="center">
            <Link href="/admin/services" fontSize="lg">
              Services
            </Link>
          </Tooltip>
        </NextLink>
        <NextLink href="/admin/locations" legacyBehavior>
          <Tooltip hasArrow label="View and search all locations" textAlign="center">
            <Link href="/admin/locations" fontSize="lg">
              Locations
            </Link>
          </Tooltip>
        </NextLink>
        {/* <NextLink href="/invoices">
          <Tooltip hasArrow label="View and search all invoices, view past due" textAlign="center">
            <Link href="/invoices" fontSize="lg">
              Invoices
            </Link>
          </Tooltip>
        </NextLink>
        <NextLink href="/payments">
          <Tooltip hasArrow label="View and search all payments" textAlign="center">
            <Link href="/payments" fontSize="lg">
              Payments
            </Link>
          </Tooltip>
        </NextLink> */}
      </ProtectedContent>
      {isMasquerading ? null : <Box whiteSpace={'nowrap'}>Hi {data?.user?.email}</Box>}
      <Button ml={16} onClick={() => signOut()}>
        Logout
      </Button>
    </Stack>
  );
}
