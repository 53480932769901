import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: any;
};

/** A stripe business profile */
export type BusinessProfile = {
  __typename?: 'BusinessProfile';
  name?: Maybe<Scalars['String']>;
};

/** A Stripe Charge */
export type Charge = {
  __typename?: 'Charge';
  amount?: Maybe<Scalars['Int']>;
  created: Scalars['Int'];
  id: Scalars['ID'];
  object: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export enum ContractLength {
  EIGHTEEN = 'EIGHTEEN',
  SIX = 'SIX',
  TWELVE = 'TWELVE',
  TWENTY_FOUR = 'TWENTY_FOUR',
  ZERO = 'ZERO'
}

/** Input to credit a patient's monthly payment plan */
export type CreditMppInput = {
  credit: Scalars['Int'];
  patientId: Scalars['String'];
};

/** A Stripe Customer */
export type Customer = {
  __typename?: 'Customer';
  balance?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  object: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

/** Input to create a new customer / user */
export type CustomerCreateInput = {
  contractEnd?: InputMaybe<Scalars['DateTime']>;
  dateOfPayment?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  eIvfId: Scalars['String'];
  email: Scalars['String'];
  hasOutstandingBalance?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  rolloverAmount?: InputMaybe<Scalars['Float']>;
};

/** Input to update an existing customer / user */
export type CustomerUpdateInput = {
  eIvfId: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

/** Type of Discount, either Percentage or Flat Rate */
export enum DiscountType {
  FLAT = 'FLAT',
  PERCENT = 'PERCENT'
}

/** Legal text for a line item */
export type FinePrint = {
  __typename?: 'FinePrint';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  quoteLineItemId: Scalars['String'];
};

/** A Stripe Invoice */
export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID'];
};

/** A line item for an Invoice */
export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  product?: Maybe<Product>;
  quantity: Scalars['Int'];
  stripeProductId: Scalars['String'];
  total?: Maybe<Scalars['Int']>;
};

/** Input to Create a new Invoice Item */
export type InvoiceLineItemCreateInput = {
  quantity?: InputMaybe<Scalars['Int']>;
  stripeProductId: Scalars['String'];
};

/** A location */
export type Location = {
  __typename?: 'Location';
  archived?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  stripeAccountId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** A location */
export type LocationInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  stripeAccountId: Scalars['String'];
};

/** A User Migration */
export type Migration = {
  __typename?: 'Migration';
  contractEnd: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  dateOfPayment: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locationId: Scalars['String'];
  quoteId?: Maybe<Scalars['String']>;
  rolloverAmount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

/** Input to create a Migration */
export type MigrationInput = {
  contractEnd: Scalars['DateTime'];
  dateOfPayment: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  locationId: Scalars['String'];
  rolloverAmount: Scalars['Int'];
  userId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Returns true when a quote is accepted */
  acceptQuote?: Maybe<Scalars['Boolean']>;
  /** Archives a location */
  archiveLocation?: Maybe<Location>;
  /** Archives a Stripe Product */
  archiveProduct?: Maybe<Product>;
  /** Archives a Customer locally */
  archiveUser?: Maybe<User>;
  /** Create Invoice for Customer */
  createCustomInvoice?: Maybe<Invoice>;
  /** Creates Stripe Customer and local User */
  createCustomer?: Maybe<User>;
  /** Creates a new location */
  createLocation?: Maybe<Location>;
  /** Creates a PaymentIntent in Stripe for a Quote if it does not already exist and returns the client secret. */
  createPaymentIntent?: Maybe<PaymentIntent>;
  /** Creates a Stripe Product */
  createProduct?: Maybe<Product>;
  /** Create Quote for Customer */
  createQuote?: Maybe<Quote>;
  /** Creates a Stripe Portal Session and redirects the user */
  createStripePortalSession?: Maybe<Scalars['String']>;
  /** Credits a customer's existing monthly payment plan by reducing the monthly payment amount. */
  creditMPP?: Maybe<Scalars['Boolean']>;
  /** Returns true when a quote is declined */
  declineQuote?: Maybe<Scalars['Boolean']>;
  /** Returns the quote after it has been resent */
  resendQuote?: Maybe<Scalars['Boolean']>;
  /** Skips a monthly payment by pushing back the monthly payment plan end date by one month */
  skipMonthlyPayment?: Maybe<Scalars['Boolean']>;
  /** Sends a Test Email via SendGrid */
  testEmail?: Maybe<Scalars['Boolean']>;
  /** Unarchives a Customer locally */
  unarchiveUser?: Maybe<User>;
  /** Updates Stripe Customer and local User */
  updateCustomer?: Maybe<Customer>;
  /** Updates a location */
  updateLocation?: Maybe<Location>;
  /** Updates a Stripe Product */
  updateProduct?: Maybe<Product>;
  /** Returns true when length of contract for a quote is updated for preview */
  updateQuotePreview?: Maybe<Scalars['Boolean']>;
  /** Returns true when a quote is voided */
  voidQuote?: Maybe<Scalars['Boolean']>;
};


export type MutationAcceptQuoteArgs = {
  paymentMethodId: Scalars['String'];
  quoteId: Scalars['String'];
};


export type MutationArchiveLocationArgs = {
  locationId: Scalars['String'];
};


export type MutationArchiveProductArgs = {
  productId: Scalars['String'];
};


export type MutationArchiveUserArgs = {
  id: Scalars['String'];
};


export type MutationCreateCustomInvoiceArgs = {
  customerId: Scalars['String'];
  invoiceLineItems: Array<InputMaybe<InvoiceLineItemCreateInput>>;
  locationId: Scalars['String'];
};


export type MutationCreateCustomerArgs = {
  data: CustomerCreateInput;
};


export type MutationCreateLocationArgs = {
  data: LocationInput;
};


export type MutationCreatePaymentIntentArgs = {
  quoteId: Scalars['String'];
};


export type MutationCreateProductArgs = {
  data: ProductCreateInput;
};


export type MutationCreateQuoteArgs = {
  data: QuoteCreateInput;
};


export type MutationCreditMppArgs = {
  data: CreditMppInput;
};


export type MutationDeclineQuoteArgs = {
  declineNote: Scalars['String'];
  quoteId: Scalars['String'];
};


export type MutationResendQuoteArgs = {
  quoteId: Scalars['String'];
};


export type MutationSkipMonthlyPaymentArgs = {
  patientId: Scalars['String'];
};


export type MutationUnarchiveUserArgs = {
  id: Scalars['String'];
};


export type MutationUpdateCustomerArgs = {
  data: CustomerUpdateInput;
};


export type MutationUpdateLocationArgs = {
  data: LocationInput;
};


export type MutationUpdateProductArgs = {
  data: ProductUpdateInput;
};


export type MutationUpdateQuotePreviewArgs = {
  contractLength: ContractLength;
  downPaymentExtra?: InputMaybe<Scalars['Float']>;
  paidInFull: Scalars['Boolean'];
  paidOutstandingBalance: Scalars['Boolean'];
  quoteId: Scalars['String'];
};


export type MutationVoidQuoteArgs = {
  quoteIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Pagination info */
export type PageInfo = {
  __typename?: 'PageInfo';
  nextPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type PageInfoWithCount = {
  __typename?: 'PageInfoWithCount';
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  lastPage: Scalars['Int'];
  nextOffset: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  prevOffset: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginatedQuote = {
  __typename?: 'PaginatedQuote';
  /** Pagination information */
  pageInfo: PageInfoWithCount;
  /** Collection of Quote */
  results: Array<Quote>;
};

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  /** Pagination information */
  pageInfo: PageInfo;
  /** Collection of customers */
  results: Array<User>;
};

/** A Stripe PaymentIntent */
export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  clientSecret?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** A product */
export type Product = {
  __typename?: 'Product';
  archived?: Maybe<Scalars['Boolean']>;
  cpt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  finePrint?: Maybe<FinePrint>;
  id: Scalars['ID'];
  name: Scalars['String'];
  recurring?: Maybe<Scalars['Boolean']>;
  recurringInterval?: Maybe<StripeProductRecurringInterval>;
  recurringIntervalCount?: Maybe<Scalars['Int']>;
  type: ProductType;
  unitAmount: Scalars['Float'];
};

/** Input to create a new stripe product */
export type ProductCreateInput = {
  cpt: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  finePrint?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  recurring?: InputMaybe<Scalars['Boolean']>;
  recurringInterval?: InputMaybe<StripeProductRecurringInterval>;
  recurringIntervalCount?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ProductType>;
};

export enum ProductType {
  ADD_ON = 'ADD_ON',
  ADMIN_FEE = 'ADMIN_FEE',
  DOWN_PAYMENT = 'DOWN_PAYMENT',
  EMBRYO_ANDRO = 'EMBRYO_ANDRO',
  MONTHLY_PAYMENT_PLAN = 'MONTHLY_PAYMENT_PLAN',
  TREATMENT = 'TREATMENT'
}

/** Input to update a stripe product */
export type ProductUpdateInput = {
  cpt: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  finePrint?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  productId: Scalars['String'];
  recurring?: InputMaybe<Scalars['Boolean']>;
  recurringInterval?: InputMaybe<StripeProductRecurringInterval>;
  recurringIntervalCount?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ProductType>;
};

/** Input to find products based other fields */
export type ProductWhereInput = {
  filter?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<InputMaybe<ProductType>>>;
};

export type Query = {
  __typename?: 'Query';
  /** The number of times an admin can skip a patients payment before being shown a warning. */
  allowedPaymentSkipCounts?: Maybe<Scalars['Int']>;
  completeQuote?: Maybe<Quote>;
  /** Returns the contract length limit */
  contractLengthLimit?: Maybe<Scalars['Int']>;
  /** Returns a Stripe Customer */
  customer?: Maybe<Customer>;
  /** Returns a paginated list of active users with the USER role */
  customers?: Maybe<PaginatedUsers>;
  legalTerms?: Maybe<QuoteLegalTerms>;
  /** Returns a location */
  location?: Maybe<Location>;
  /** Returns a list of locations */
  locations?: Maybe<Array<Maybe<Location>>>;
  /** Returns the currently logged in user */
  me?: Maybe<User>;
  patientQuote?: Maybe<Quote>;
  patientQuotes?: Maybe<Array<Maybe<Quote>>>;
  /** Returns a single product */
  product?: Maybe<Product>;
  /** Returns a list of products */
  products?: Maybe<Array<Maybe<Product>>>;
  /** Returns the base of a single Quote */
  quote?: Maybe<Quote>;
  /** Returns a paginated list of quotes */
  quotes?: Maybe<PaginatedQuote>;
  /** Returns a list of stripe accounts */
  stripeAccounts?: Maybe<Array<Maybe<StripeConnectedAccount>>>;
  /** Returns a Stripe customer's upcoming invoice */
  upcomingInvoice?: Maybe<UpcomingInvoice>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryCompleteQuoteArgs = {
  where: QuoteWhereUniqueInput;
};


export type QueryCustomerArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryCustomersArgs = {
  filter?: InputMaybe<Scalars['String']>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryLocationArgs = {
  id: Scalars['String'];
};


export type QueryPatientQuoteArgs = {
  where: QuoteWhereUniqueInput;
};


export type QueryProductArgs = {
  productId?: InputMaybe<Scalars['String']>;
};


export type QueryProductsArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryQuoteArgs = {
  where: QuoteWhereUniqueInput;
};


export type QueryQuotesArgs = {
  filter?: InputMaybe<QuotesFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryUpcomingInvoiceArgs = {
  customerId: Scalars['ID'];
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

/** A Quote */
export type Quote = {
  __typename?: 'Quote';
  amountTotal?: Maybe<Scalars['Int']>;
  /** Virtual field calculated based on startDate and endDate of quote */
  contractLength: Scalars['Int'];
  contractSigned?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  customerId: Scalars['String'];
  declineNote: Scalars['String'];
  depositPercent?: Maybe<Scalars['Float']>;
  downPaymentExtra?: Maybe<Scalars['Int']>;
  downPaymentTotal?: Maybe<Scalars['Int']>;
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  locationId?: Maybe<Scalars['String']>;
  migration?: Maybe<Migration>;
  monthlyTotal: Scalars['Int'];
  paidInFull: Scalars['Boolean'];
  paidInFullDiscount?: Maybe<Scalars['Int']>;
  patient?: Maybe<User>;
  previousQuote?: Maybe<Quote>;
  /** Returns an array of products/services, their quantities, and totals */
  previousQuoteLineItems?: Maybe<Array<Maybe<QuoteLineItem>>>;
  /** Returns an array of products/services, their quantities, and totals */
  quoteLineItems?: Maybe<Array<Maybe<QuoteLineItem>>>;
  rolloverTotal: Scalars['Int'];
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  /** Virtual field to help the FE display financing options */
  timeLeftInContract?: Maybe<Scalars['Int']>;
};

/** Input to create a new quote */
export type QuoteCreateInput = {
  csvUpload?: InputMaybe<Scalars['Boolean']>;
  customerId: Scalars['String'];
  depositPercent: Scalars['Float'];
  locationId: Scalars['String'];
  quoteLineItems?: InputMaybe<Array<InputMaybe<QuoteLineItemCreateInput>>>;
};

/** Legal terms which patients are required to accept to start their MPP. */
export type QuoteLegalTerms = {
  __typename?: 'QuoteLegalTerms';
  mppTermsOfService: Scalars['String'];
  rolloverTermsOfService: Scalars['String'];
};

/** A line item for a Quote */
export type QuoteLineItem = {
  __typename?: 'QuoteLineItem';
  discountAmount?: Maybe<Scalars['Int']>;
  discountDescription?: Maybe<Scalars['String']>;
  discountType?: Maybe<DiscountType>;
  finePrint?: Maybe<FinePrint>;
  hasDiscount: Scalars['Boolean'];
  product?: Maybe<Product>;
  quantity: Scalars['Int'];
  quoteId: Scalars['String'];
  requiresPayInFull?: Maybe<Scalars['Boolean']>;
  stripeProductId: Scalars['String'];
  total?: Maybe<Scalars['Int']>;
};

/** Input to Create a new Quote Item */
export type QuoteLineItemCreateInput = {
  discountAmount?: InputMaybe<Scalars['Int']>;
  discountDescription?: InputMaybe<Scalars['String']>;
  discountType?: InputMaybe<DiscountType>;
  finePrint?: InputMaybe<Scalars['String']>;
  hasDiscount: Scalars['Boolean'];
  quantity?: InputMaybe<Scalars['Int']>;
  requiresPayInFull?: InputMaybe<Scalars['Boolean']>;
  stripeProductId: Scalars['String'];
};

export enum QuoteSortBy {
  EIVFID = 'EIVFID',
  PATIENT_NAME = 'PATIENT_NAME'
}

export enum QuoteSortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum QuoteStatus {
  DRAFT = 'DRAFT',
  PATIENT_ACCEPTED = 'PATIENT_ACCEPTED',
  PATIENT_DECLINED = 'PATIENT_DECLINED',
  PENDING_RESPONSE = 'PENDING_RESPONSE',
  SENDING = 'SENDING',
  SENDING_FAILED = 'SENDING_FAILED',
  VOIDED = 'VOIDED'
}

/** Input to find quotes based other fields */
export type QuoteWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Input to find quotes based on unique fields */
export type QuoteWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Input to filter the quotes list */
export type QuotesFilterInput = {
  includeStatus?: InputMaybe<Array<InputMaybe<QuoteStatus>>>;
  sentDateEnd?: InputMaybe<Scalars['DateTime']>;
  sentDateStart?: InputMaybe<Scalars['DateTime']>;
  signedDateEnd?: InputMaybe<Scalars['DateTime']>;
  signedDateStart?: InputMaybe<Scalars['DateTime']>;
  sortBy?: InputMaybe<QuoteSortBy>;
  sortOrder?: InputMaybe<QuoteSortOrder>;
};

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER'
}

/** Sort direction for filtering queries (ascending or descending) */
export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

/** A way to filter string fields. Meant to pass to prisma where clause */
export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

/** A Stripe Connected Account */
export type StripeConnectedAccount = {
  __typename?: 'StripeConnectedAccount';
  business_profile?: Maybe<BusinessProfile>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
};

/** Recurring interval for a stripe product */
export enum StripeProductRecurringInterval {
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

/** A Stripe Upcoming Invoice */
export type UpcomingInvoice = {
  __typename?: 'UpcomingInvoice';
  period_start: Scalars['DateTime'];
  total: Scalars['Int'];
};

/** A User */
export type User = {
  __typename?: 'User';
  archived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  eIvfId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  has_subscription?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  migration?: Maybe<Migration>;
  mpp_balance?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  paymentSkipCount: Scalars['Int'];
  quotes?: Maybe<Array<Maybe<Quote>>>;
  recentCharges?: Maybe<Array<Maybe<Charge>>>;
  roles: Array<Role>;
  timeLeftInContract?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};


/** A User */
export type UserQuotesArgs = {
  excludeVoided?: InputMaybe<Scalars['Boolean']>;
};

/** Order users by a specific field */
export type UserOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Input to find users based other fields */
export type UserWhereInput = {
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['Int']>;
};

/** Input to find users based on unique fields */
export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type CreateLocationMutationVariables = Exact<{
  data: LocationInput;
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createLocation?: { __typename?: 'Location', id: string } | null };

export type CreateCustomerMutationVariables = Exact<{
  data: CustomerCreateInput;
}>;


export type CreateCustomerMutation = { __typename?: 'Mutation', createCustomer?: { __typename?: 'User', id: string } | null };

export type CreateProductMutationVariables = Exact<{
  data: ProductCreateInput;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct?: { __typename?: 'Product', id: string } | null };

export type ApplyCreditMutationVariables = Exact<{
  data: CreditMppInput;
}>;


export type ApplyCreditMutation = { __typename?: 'Mutation', creditMPP?: boolean | null };

export type CreateCustomInvoiceMutationVariables = Exact<{
  customerId: Scalars['String'];
  locationId: Scalars['String'];
  invoiceLineItems: Array<InputMaybe<InvoiceLineItemCreateInput>> | InputMaybe<InvoiceLineItemCreateInput>;
}>;


export type CreateCustomInvoiceMutation = { __typename?: 'Mutation', createCustomInvoice?: { __typename?: 'Invoice', id: string } | null };

export type ProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsQuery = { __typename?: 'Query', products?: Array<{ __typename?: 'Product', description?: string | null, id: string, name: string, unitAmount: number, type: ProductType, finePrint?: { __typename?: 'FinePrint', id: string, description?: string | null } | null } | null> | null };

export type CreateQuoteMutationVariables = Exact<{
  data: QuoteCreateInput;
}>;


export type CreateQuoteMutation = { __typename?: 'Mutation', createQuote?: { __typename?: 'Quote', id: string } | null };

export type ArchiveLocationMutationVariables = Exact<{
  locationId: Scalars['String'];
}>;


export type ArchiveLocationMutation = { __typename?: 'Mutation', archiveLocation?: { __typename?: 'Location', id: string } | null };

export type UpdateLocationMutationVariables = Exact<{
  data: LocationInput;
}>;


export type UpdateLocationMutation = { __typename?: 'Mutation', updateLocation?: { __typename?: 'Location', id: string } | null };

export type UpdateCustomerMutationVariables = Exact<{
  data: CustomerUpdateInput;
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', updateCustomer?: { __typename?: 'Customer', id: string } | null };

export type ArchiveUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ArchiveUserMutation = { __typename?: 'Mutation', archiveUser?: { __typename?: 'User', id: string, archived: boolean } | null };

export type UnarchiveUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type UnarchiveUserMutation = { __typename?: 'Mutation', unarchiveUser?: { __typename?: 'User', id: string, archived: boolean } | null };

export type LocationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type LocationQuery = { __typename?: 'Query', location?: { __typename?: 'Location', id: string, name: string } | null };

export type GetProductQueryVariables = Exact<{
  productId: Scalars['String'];
}>;


export type GetProductQuery = { __typename?: 'Query', product?: { __typename?: 'Product', id: string, name: string, description?: string | null, unitAmount: number, cpt?: string | null, type: ProductType, archived?: boolean | null, recurring?: boolean | null, recurringInterval?: StripeProductRecurringInterval | null, recurringIntervalCount?: number | null, finePrint?: { __typename?: 'FinePrint', id: string, description?: string | null } | null } | null };

export type UpdateProductMutationVariables = Exact<{
  data: ProductUpdateInput;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct?: { __typename?: 'Product', id: string } | null };

export type ArchiveProductMutationVariables = Exact<{
  productId: Scalars['String'];
}>;


export type ArchiveProductMutation = { __typename?: 'Mutation', archiveProduct?: { __typename?: 'Product', id: string } | null };

export type StripeAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type StripeAccountsQuery = { __typename?: 'Query', stripeAccounts?: Array<{ __typename?: 'StripeConnectedAccount', id: string, country?: string | null, type?: string | null, business_profile?: { __typename?: 'BusinessProfile', name?: string | null } | null } | null> | null };

export type PatientQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PatientQuery = { __typename?: 'Query', user?: { __typename?: 'User', eIvfId?: string | null, id: string, name?: string | null, mpp_balance?: number | null, has_subscription?: boolean | null, timeLeftInContract?: number | null, archived: boolean, paymentSkipCount: number, customer?: { __typename?: 'Customer', id: string, object: string, name?: string | null, email?: string | null, phone?: string | null } | null, quotes?: Array<{ __typename?: 'Quote', id: string, amountTotal?: number | null, downPaymentTotal?: number | null, depositPercent?: number | null, downPaymentExtra?: number | null, monthlyTotal: number, status: string, contractSigned?: any | null, contractLength: number, createdAt: any, customerId: string, locationId?: string | null, endDate: any, startDate: any, paidInFull: boolean, paidInFullDiscount?: number | null, rolloverTotal: number, declineNote: string, createdBy?: { __typename?: 'User', id: string, name?: string | null, email?: string | null } | null, migration?: { __typename?: 'Migration', rolloverAmount: number, description?: string | null } | null, quoteLineItems?: Array<{ __typename?: 'QuoteLineItem', quoteId: string, quantity: number, total?: number | null, stripeProductId: string, hasDiscount: boolean, discountType?: DiscountType | null, discountAmount?: number | null, discountDescription?: string | null, requiresPayInFull?: boolean | null, product?: { __typename?: 'Product', id: string, name: string } | null, finePrint?: { __typename?: 'FinePrint', description?: string | null } | null } | null> | null } | null> | null, recentCharges?: Array<{ __typename?: 'Charge', id: string, object: string, amount?: number | null, status?: string | null, created: number } | null> | null, migration?: { __typename?: 'Migration', id: string, quoteId?: string | null, contractEnd: any, dateOfPayment: number, locationId: string, rolloverAmount: number, description?: string | null, createdAt: any, updatedAt: any, userId: string } | null } | null };

export type AllowedPaymentSkipCountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllowedPaymentSkipCountsQuery = { __typename?: 'Query', allowedPaymentSkipCounts?: number | null };

export type ResendQuoteMutationVariables = Exact<{
  quoteId: Scalars['String'];
}>;


export type ResendQuoteMutation = { __typename?: 'Mutation', resendQuote?: boolean | null };

export type SkipPaymentMutationVariables = Exact<{
  patientId: Scalars['String'];
}>;


export type SkipPaymentMutation = { __typename?: 'Mutation', skipMonthlyPayment?: boolean | null };

export type PatientPendingQuotesQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientPendingQuotesQuery = { __typename?: 'Query', patientQuotes?: Array<{ __typename?: 'Quote', amountTotal?: number | null, createdAt: any, id: string, quoteLineItems?: Array<{ __typename?: 'QuoteLineItem', quantity: number, stripeProductId: string, total?: number | null, product?: { __typename?: 'Product', name: string } | null } | null> | null } | null> | null };

export type UpcomingInvoiceQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;


export type UpcomingInvoiceQuery = { __typename?: 'Query', upcomingInvoice?: { __typename?: 'UpcomingInvoice', period_start: any, total: number } | null, user?: { __typename?: 'User', mpp_balance?: number | null } | null };

export type ProductOptionsQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['String']>;
}>;


export type ProductOptionsQuery = { __typename?: 'Query', products?: Array<{ __typename?: 'Product', cpt?: string | null, id: string, name: string, type: ProductType, unitAmount: number } | null> | null };

export type AcceptQuoteMutationVariables = Exact<{
  quoteId: Scalars['String'];
  paymentMethodId: Scalars['String'];
}>;


export type AcceptQuoteMutation = { __typename?: 'Mutation', acceptQuote?: boolean | null };

export type DeclineQuoteMutationVariables = Exact<{
  quoteId: Scalars['String'];
  declineNote: Scalars['String'];
}>;


export type DeclineQuoteMutation = { __typename?: 'Mutation', declineQuote?: boolean | null };

export type LegalTermsQueryVariables = Exact<{ [key: string]: never; }>;


export type LegalTermsQuery = { __typename?: 'Query', legalTerms?: { __typename?: 'QuoteLegalTerms', mppTermsOfService: string, rolloverTermsOfService: string } | null };

export type PatientQuoteQueryVariables = Exact<{
  where: QuoteWhereUniqueInput;
}>;


export type PatientQuoteQuery = { __typename?: 'Query', patientQuote?: { __typename?: 'Quote', id: string, amountTotal?: number | null, contractLength: number, downPaymentTotal?: number | null, downPaymentExtra?: number | null, monthlyTotal: number, status: string, startDate: any, endDate: any, paidInFull: boolean, paidInFullDiscount?: number | null, rolloverTotal: number, timeLeftInContract?: number | null, patient?: { __typename?: 'User', id: string, name?: string | null } | null, quoteLineItems?: Array<{ __typename?: 'QuoteLineItem', total?: number | null, stripeProductId: string, quantity: number, hasDiscount: boolean, discountAmount?: number | null, discountDescription?: string | null, discountType?: DiscountType | null, requiresPayInFull?: boolean | null, product?: { __typename?: 'Product', name: string } | null, finePrint?: { __typename?: 'FinePrint', id: string, description?: string | null } | null } | null> | null, previousQuoteLineItems?: Array<{ __typename?: 'QuoteLineItem', total?: number | null, stripeProductId: string, quantity: number, product?: { __typename?: 'Product', name: string } | null } | null> | null, migration?: { __typename?: 'Migration', rolloverAmount: number, description?: string | null, contractEnd: any } | null } | null };

export type UpdateQuotePreviewMutationVariables = Exact<{
  quoteId: Scalars['String'];
  contractLength: ContractLength;
  paidInFull: Scalars['Boolean'];
  paidOutstandingBalance: Scalars['Boolean'];
  downPaymentExtra?: InputMaybe<Scalars['Float']>;
}>;


export type UpdateQuotePreviewMutation = { __typename?: 'Mutation', updateQuotePreview?: boolean | null };

export type CreatePaymentIntentMutationVariables = Exact<{
  quoteId: Scalars['String'];
}>;


export type CreatePaymentIntentMutation = { __typename?: 'Mutation', createPaymentIntent?: { __typename?: 'PaymentIntent', id: string, clientSecret?: string | null } | null };

export type TestEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type TestEmailMutation = { __typename?: 'Mutation', testEmail?: boolean | null };

export type VoidQuoteMutationVariables = Exact<{
  quoteIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type VoidQuoteMutation = { __typename?: 'Mutation', voidQuote?: boolean | null };

export type CompleteQuoteQueryVariables = Exact<{
  where: QuoteWhereUniqueInput;
}>;


export type CompleteQuoteQuery = { __typename?: 'Query', completeQuote?: { __typename?: 'Quote', id: string, createdAt: any, contractSigned?: any | null, status: string, amountTotal?: number | null, downPaymentTotal?: number | null, depositPercent?: number | null, downPaymentExtra?: number | null, monthlyTotal: number, contractLength: number, customerId: string, locationId?: string | null, startDate: any, paidInFull: boolean, paidInFullDiscount?: number | null, endDate: any, rolloverTotal: number, declineNote: string, migration?: { __typename?: 'Migration', rolloverAmount: number, description?: string | null } | null, quoteLineItems?: Array<{ __typename?: 'QuoteLineItem', quoteId: string, quantity: number, total?: number | null, stripeProductId: string, hasDiscount: boolean, discountType?: DiscountType | null, discountAmount?: number | null, discountDescription?: string | null, requiresPayInFull?: boolean | null, product?: { __typename?: 'Product', id: string, name: string } | null, finePrint?: { __typename?: 'FinePrint', description?: string | null } | null } | null> | null } | null };

export type LocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type LocationsQuery = { __typename?: 'Query', locations?: Array<{ __typename?: 'Location', id: string, name: string, stripeAccountId: string, archived?: boolean | null } | null> | null };

export type CustomersQueryVariables = Exact<{
  page?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  filter?: InputMaybe<Scalars['String']>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
}>;


export type CustomersQuery = { __typename?: 'Query', customers?: { __typename?: 'PaginatedUsers', pageInfo: { __typename?: 'PageInfo', nextPage?: number | null, totalPages?: number | null }, results: Array<{ __typename?: 'User', id: string, eIvfId?: string | null, email?: string | null, name?: string | null, archived: boolean }> } | null };

export type QuotesQueryVariables = Exact<{
  quoteFilter?: InputMaybe<QuotesFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;


export type QuotesQuery = { __typename?: 'Query', quotes?: { __typename?: 'PaginatedQuote', results: Array<{ __typename?: 'Quote', id: string, createdAt: any, contractSigned?: any | null, status: string, patient?: { __typename?: 'User', id: string, email?: string | null, name?: string | null, eIvfId?: string | null } | null, createdBy?: { __typename?: 'User', id: string, name?: string | null, email?: string | null } | null }>, pageInfo: { __typename?: 'PageInfoWithCount', page: number, nextPage?: number | null, totalPages: number, totalCount: number, nextOffset: number, prevOffset: number, hasNextPage: boolean, hasPrevPage: boolean, lastPage: number } } | null };

export type ServicesListQueryVariables = Exact<{ [key: string]: never; }>;


export type ServicesListQuery = { __typename?: 'Query', products?: Array<{ __typename?: 'Product', id: string, name: string, unitAmount: number } | null> | null };

export type CreateStripePortalSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStripePortalSessionMutation = { __typename?: 'Mutation', createStripePortalSession?: string | null };

export type PatientDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PatientDetailsQuery = { __typename?: 'Query', user?: { __typename?: 'User', customer?: { __typename?: 'Customer', name?: string | null } | null, quotes?: Array<{ __typename?: 'Quote', id: string, amountTotal?: number | null, downPaymentTotal?: number | null, downPaymentExtra?: number | null, monthlyTotal: number, status: string, declineNote: string, contractSigned?: any | null, contractLength: number, createdAt: any, customerId: string, locationId?: string | null, endDate: any, startDate: any, paidInFull: boolean, paidInFullDiscount?: number | null, rolloverTotal: number, migration?: { __typename?: 'Migration', rolloverAmount: number, description?: string | null } | null, quoteLineItems?: Array<{ __typename?: 'QuoteLineItem', quoteId: string, quantity: number, total?: number | null, stripeProductId: string, hasDiscount: boolean, discountType?: DiscountType | null, discountAmount?: number | null, discountDescription?: string | null, product?: { __typename?: 'Product', id: string, name: string } | null, finePrint?: { __typename?: 'FinePrint', description?: string | null } | null } | null> | null } | null> | null, recentCharges?: Array<{ __typename?: 'Charge', id: string, object: string, amount?: number | null, status?: string | null, created: number } | null> | null } | null };

export type ContractLengthLimitQueryVariables = Exact<{ [key: string]: never; }>;


export type ContractLengthLimitQuery = { __typename?: 'Query', contractLengthLimit?: number | null };


export const CreateLocationDocument = gql`
    mutation createLocation($data: LocationInput!) {
  createLocation(data: $data) {
    id
  }
}
    `;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }

export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($data: CustomerCreateInput!) {
  createCustomer(data: $data) {
    id
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }

export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($data: ProductCreateInput!) {
  createProduct(data: $data) {
    id
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }

export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const ApplyCreditDocument = gql`
    mutation applyCredit($data: CreditMPPInput!) {
  creditMPP(data: $data)
}
    `;
export type ApplyCreditMutationFn = Apollo.MutationFunction<ApplyCreditMutation, ApplyCreditMutationVariables>;

/**
 * __useApplyCreditMutation__
 *
 * To run a mutation, you first call `useApplyCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyCreditMutation, { data, loading, error }] = useApplyCreditMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useApplyCreditMutation(baseOptions?: Apollo.MutationHookOptions<ApplyCreditMutation, ApplyCreditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyCreditMutation, ApplyCreditMutationVariables>(ApplyCreditDocument, options);
      }

export type ApplyCreditMutationHookResult = ReturnType<typeof useApplyCreditMutation>;
export type ApplyCreditMutationResult = Apollo.MutationResult<ApplyCreditMutation>;
export type ApplyCreditMutationOptions = Apollo.BaseMutationOptions<ApplyCreditMutation, ApplyCreditMutationVariables>;
export const CreateCustomInvoiceDocument = gql`
    mutation createCustomInvoice($customerId: String!, $locationId: String!, $invoiceLineItems: [InvoiceLineItemCreateInput]!) {
  createCustomInvoice(
    customerId: $customerId
    locationId: $locationId
    invoiceLineItems: $invoiceLineItems
  ) {
    id
  }
}
    `;
export type CreateCustomInvoiceMutationFn = Apollo.MutationFunction<CreateCustomInvoiceMutation, CreateCustomInvoiceMutationVariables>;

/**
 * __useCreateCustomInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateCustomInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomInvoiceMutation, { data, loading, error }] = useCreateCustomInvoiceMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      locationId: // value for 'locationId'
 *      invoiceLineItems: // value for 'invoiceLineItems'
 *   },
 * });
 */
export function useCreateCustomInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomInvoiceMutation, CreateCustomInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomInvoiceMutation, CreateCustomInvoiceMutationVariables>(CreateCustomInvoiceDocument, options);
      }

export type CreateCustomInvoiceMutationHookResult = ReturnType<typeof useCreateCustomInvoiceMutation>;
export type CreateCustomInvoiceMutationResult = Apollo.MutationResult<CreateCustomInvoiceMutation>;
export type CreateCustomInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateCustomInvoiceMutation, CreateCustomInvoiceMutationVariables>;
export const ProductsDocument = gql`
    query products {
  products(where: {types: [ADD_ON, EMBRYO_ANDRO, TREATMENT]}) {
    description
    finePrint {
      id
      description
    }
    id
    name
    unitAmount
    type
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }

export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }

export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const CreateQuoteDocument = gql`
    mutation createQuote($data: QuoteCreateInput!) {
  createQuote(data: $data) {
    id
  }
}
    `;
export type CreateQuoteMutationFn = Apollo.MutationFunction<CreateQuoteMutation, CreateQuoteMutationVariables>;

/**
 * __useCreateQuoteMutation__
 *
 * To run a mutation, you first call `useCreateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteMutation, { data, loading, error }] = useCreateQuoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteMutation, CreateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteMutation, CreateQuoteMutationVariables>(CreateQuoteDocument, options);
      }

export type CreateQuoteMutationHookResult = ReturnType<typeof useCreateQuoteMutation>;
export type CreateQuoteMutationResult = Apollo.MutationResult<CreateQuoteMutation>;
export type CreateQuoteMutationOptions = Apollo.BaseMutationOptions<CreateQuoteMutation, CreateQuoteMutationVariables>;
export const ArchiveLocationDocument = gql`
    mutation archiveLocation($locationId: String!) {
  archiveLocation(locationId: $locationId) {
    id
  }
}
    `;
export type ArchiveLocationMutationFn = Apollo.MutationFunction<ArchiveLocationMutation, ArchiveLocationMutationVariables>;

/**
 * __useArchiveLocationMutation__
 *
 * To run a mutation, you first call `useArchiveLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveLocationMutation, { data, loading, error }] = useArchiveLocationMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useArchiveLocationMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveLocationMutation, ArchiveLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveLocationMutation, ArchiveLocationMutationVariables>(ArchiveLocationDocument, options);
      }

export type ArchiveLocationMutationHookResult = ReturnType<typeof useArchiveLocationMutation>;
export type ArchiveLocationMutationResult = Apollo.MutationResult<ArchiveLocationMutation>;
export type ArchiveLocationMutationOptions = Apollo.BaseMutationOptions<ArchiveLocationMutation, ArchiveLocationMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation updateLocation($data: LocationInput!) {
  updateLocation(data: $data) {
    id
  }
}
    `;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }

export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($data: CustomerUpdateInput!) {
  updateCustomer(data: $data) {
    id
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }

export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const ArchiveUserDocument = gql`
    mutation archiveUser($id: String!) {
  archiveUser(id: $id) {
    id
    archived
  }
}
    `;
export type ArchiveUserMutationFn = Apollo.MutationFunction<ArchiveUserMutation, ArchiveUserMutationVariables>;

/**
 * __useArchiveUserMutation__
 *
 * To run a mutation, you first call `useArchiveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveUserMutation, { data, loading, error }] = useArchiveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveUserMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveUserMutation, ArchiveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveUserMutation, ArchiveUserMutationVariables>(ArchiveUserDocument, options);
      }

export type ArchiveUserMutationHookResult = ReturnType<typeof useArchiveUserMutation>;
export type ArchiveUserMutationResult = Apollo.MutationResult<ArchiveUserMutation>;
export type ArchiveUserMutationOptions = Apollo.BaseMutationOptions<ArchiveUserMutation, ArchiveUserMutationVariables>;
export const UnarchiveUserDocument = gql`
    mutation unarchiveUser($id: String!) {
  unarchiveUser(id: $id) {
    id
    archived
  }
}
    `;
export type UnarchiveUserMutationFn = Apollo.MutationFunction<UnarchiveUserMutation, UnarchiveUserMutationVariables>;

/**
 * __useUnarchiveUserMutation__
 *
 * To run a mutation, you first call `useUnarchiveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveUserMutation, { data, loading, error }] = useUnarchiveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveUserMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveUserMutation, UnarchiveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnarchiveUserMutation, UnarchiveUserMutationVariables>(UnarchiveUserDocument, options);
      }

export type UnarchiveUserMutationHookResult = ReturnType<typeof useUnarchiveUserMutation>;
export type UnarchiveUserMutationResult = Apollo.MutationResult<UnarchiveUserMutation>;
export type UnarchiveUserMutationOptions = Apollo.BaseMutationOptions<UnarchiveUserMutation, UnarchiveUserMutationVariables>;
export const LocationDocument = gql`
    query location($id: String!) {
  location(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationQuery(baseOptions: Apollo.QueryHookOptions<LocationQuery, LocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options);
      }

export function useLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationQuery, LocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options);
        }

export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>;
export type LocationLazyQueryHookResult = ReturnType<typeof useLocationLazyQuery>;
export type LocationQueryResult = Apollo.QueryResult<LocationQuery, LocationQueryVariables>;
export const GetProductDocument = gql`
    query getProduct($productId: String!) {
  product(productId: $productId) {
    id
    name
    description
    unitAmount
    finePrint {
      id
      description
    }
    cpt
    type
    archived
    recurring
    recurringInterval
    recurringIntervalCount
  }
}
    `;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
      }

export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
        }

export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($data: ProductUpdateInput!) {
  updateProduct(data: $data) {
    id
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }

export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const ArchiveProductDocument = gql`
    mutation archiveProduct($productId: String!) {
  archiveProduct(productId: $productId) {
    id
  }
}
    `;
export type ArchiveProductMutationFn = Apollo.MutationFunction<ArchiveProductMutation, ArchiveProductMutationVariables>;

/**
 * __useArchiveProductMutation__
 *
 * To run a mutation, you first call `useArchiveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProductMutation, { data, loading, error }] = useArchiveProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useArchiveProductMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveProductMutation, ArchiveProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveProductMutation, ArchiveProductMutationVariables>(ArchiveProductDocument, options);
      }

export type ArchiveProductMutationHookResult = ReturnType<typeof useArchiveProductMutation>;
export type ArchiveProductMutationResult = Apollo.MutationResult<ArchiveProductMutation>;
export type ArchiveProductMutationOptions = Apollo.BaseMutationOptions<ArchiveProductMutation, ArchiveProductMutationVariables>;
export const StripeAccountsDocument = gql`
    query stripeAccounts {
  stripeAccounts {
    id
    country
    type
    business_profile {
      name
    }
  }
}
    `;

/**
 * __useStripeAccountsQuery__
 *
 * To run a query within a React component, call `useStripeAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeAccountsQuery(baseOptions?: Apollo.QueryHookOptions<StripeAccountsQuery, StripeAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeAccountsQuery, StripeAccountsQueryVariables>(StripeAccountsDocument, options);
      }

export function useStripeAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeAccountsQuery, StripeAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeAccountsQuery, StripeAccountsQueryVariables>(StripeAccountsDocument, options);
        }

export type StripeAccountsQueryHookResult = ReturnType<typeof useStripeAccountsQuery>;
export type StripeAccountsLazyQueryHookResult = ReturnType<typeof useStripeAccountsLazyQuery>;
export type StripeAccountsQueryResult = Apollo.QueryResult<StripeAccountsQuery, StripeAccountsQueryVariables>;
export const PatientDocument = gql`
    query patient($id: ID!) {
  user(where: {id: $id}) {
    customer {
      id
      object
      name
      email
      phone
    }
    eIvfId
    id
    name
    mpp_balance
    has_subscription
    timeLeftInContract
    archived
    paymentSkipCount
    quotes {
      id
      createdBy {
        id
        name
        email
      }
      amountTotal
      downPaymentTotal
      depositPercent
      downPaymentExtra
      monthlyTotal
      status
      contractSigned
      contractLength
      createdAt
      customerId
      locationId
      endDate
      startDate
      paidInFull
      paidInFullDiscount
      rolloverTotal
      migration {
        rolloverAmount
        description
      }
      declineNote
      quoteLineItems {
        quoteId
        quantity
        total
        stripeProductId
        product {
          id
          name
        }
        hasDiscount
        discountType
        discountAmount
        discountDescription
        requiresPayInFull
        finePrint {
          description
        }
      }
    }
    recentCharges {
      id
      object
      amount
      status
      created
    }
    migration {
      id
      quoteId
      contractEnd
      dateOfPayment
      locationId
      rolloverAmount
      description
      createdAt
      updatedAt
      userId
    }
  }
}
    `;

/**
 * __usePatientQuery__
 *
 * To run a query within a React component, call `usePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientQuery(baseOptions: Apollo.QueryHookOptions<PatientQuery, PatientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientQuery, PatientQueryVariables>(PatientDocument, options);
      }

export function usePatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientQuery, PatientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientQuery, PatientQueryVariables>(PatientDocument, options);
        }

export type PatientQueryHookResult = ReturnType<typeof usePatientQuery>;
export type PatientLazyQueryHookResult = ReturnType<typeof usePatientLazyQuery>;
export type PatientQueryResult = Apollo.QueryResult<PatientQuery, PatientQueryVariables>;
export const AllowedPaymentSkipCountsDocument = gql`
    query AllowedPaymentSkipCounts {
  allowedPaymentSkipCounts
}
    `;

/**
 * __useAllowedPaymentSkipCountsQuery__
 *
 * To run a query within a React component, call `useAllowedPaymentSkipCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowedPaymentSkipCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowedPaymentSkipCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllowedPaymentSkipCountsQuery(baseOptions?: Apollo.QueryHookOptions<AllowedPaymentSkipCountsQuery, AllowedPaymentSkipCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllowedPaymentSkipCountsQuery, AllowedPaymentSkipCountsQueryVariables>(AllowedPaymentSkipCountsDocument, options);
      }

export function useAllowedPaymentSkipCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllowedPaymentSkipCountsQuery, AllowedPaymentSkipCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllowedPaymentSkipCountsQuery, AllowedPaymentSkipCountsQueryVariables>(AllowedPaymentSkipCountsDocument, options);
        }

export type AllowedPaymentSkipCountsQueryHookResult = ReturnType<typeof useAllowedPaymentSkipCountsQuery>;
export type AllowedPaymentSkipCountsLazyQueryHookResult = ReturnType<typeof useAllowedPaymentSkipCountsLazyQuery>;
export type AllowedPaymentSkipCountsQueryResult = Apollo.QueryResult<AllowedPaymentSkipCountsQuery, AllowedPaymentSkipCountsQueryVariables>;
export const ResendQuoteDocument = gql`
    mutation resendQuote($quoteId: String!) {
  resendQuote(quoteId: $quoteId)
}
    `;
export type ResendQuoteMutationFn = Apollo.MutationFunction<ResendQuoteMutation, ResendQuoteMutationVariables>;

/**
 * __useResendQuoteMutation__
 *
 * To run a mutation, you first call `useResendQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendQuoteMutation, { data, loading, error }] = useResendQuoteMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useResendQuoteMutation(baseOptions?: Apollo.MutationHookOptions<ResendQuoteMutation, ResendQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendQuoteMutation, ResendQuoteMutationVariables>(ResendQuoteDocument, options);
      }

export type ResendQuoteMutationHookResult = ReturnType<typeof useResendQuoteMutation>;
export type ResendQuoteMutationResult = Apollo.MutationResult<ResendQuoteMutation>;
export type ResendQuoteMutationOptions = Apollo.BaseMutationOptions<ResendQuoteMutation, ResendQuoteMutationVariables>;
export const SkipPaymentDocument = gql`
    mutation SkipPayment($patientId: String!) {
  skipMonthlyPayment(patientId: $patientId)
}
    `;
export type SkipPaymentMutationFn = Apollo.MutationFunction<SkipPaymentMutation, SkipPaymentMutationVariables>;

/**
 * __useSkipPaymentMutation__
 *
 * To run a mutation, you first call `useSkipPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipPaymentMutation, { data, loading, error }] = useSkipPaymentMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useSkipPaymentMutation(baseOptions?: Apollo.MutationHookOptions<SkipPaymentMutation, SkipPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SkipPaymentMutation, SkipPaymentMutationVariables>(SkipPaymentDocument, options);
      }

export type SkipPaymentMutationHookResult = ReturnType<typeof useSkipPaymentMutation>;
export type SkipPaymentMutationResult = Apollo.MutationResult<SkipPaymentMutation>;
export type SkipPaymentMutationOptions = Apollo.BaseMutationOptions<SkipPaymentMutation, SkipPaymentMutationVariables>;
export const PatientPendingQuotesDocument = gql`
    query patientPendingQuotes {
  patientQuotes {
    amountTotal
    createdAt
    id
    quoteLineItems {
      product {
        name
      }
      quantity
      stripeProductId
      total
    }
  }
}
    `;

/**
 * __usePatientPendingQuotesQuery__
 *
 * To run a query within a React component, call `usePatientPendingQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientPendingQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientPendingQuotesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientPendingQuotesQuery(baseOptions?: Apollo.QueryHookOptions<PatientPendingQuotesQuery, PatientPendingQuotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientPendingQuotesQuery, PatientPendingQuotesQueryVariables>(PatientPendingQuotesDocument, options);
      }

export function usePatientPendingQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientPendingQuotesQuery, PatientPendingQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientPendingQuotesQuery, PatientPendingQuotesQueryVariables>(PatientPendingQuotesDocument, options);
        }

export type PatientPendingQuotesQueryHookResult = ReturnType<typeof usePatientPendingQuotesQuery>;
export type PatientPendingQuotesLazyQueryHookResult = ReturnType<typeof usePatientPendingQuotesLazyQuery>;
export type PatientPendingQuotesQueryResult = Apollo.QueryResult<PatientPendingQuotesQuery, PatientPendingQuotesQueryVariables>;
export const UpcomingInvoiceDocument = gql`
    query upcomingInvoice($customerId: ID!) {
  upcomingInvoice(customerId: $customerId) {
    period_start
    total
  }
  user(where: {id: $customerId}) {
    mpp_balance
  }
}
    `;

/**
 * __useUpcomingInvoiceQuery__
 *
 * To run a query within a React component, call `useUpcomingInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingInvoiceQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useUpcomingInvoiceQuery(baseOptions: Apollo.QueryHookOptions<UpcomingInvoiceQuery, UpcomingInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpcomingInvoiceQuery, UpcomingInvoiceQueryVariables>(UpcomingInvoiceDocument, options);
      }

export function useUpcomingInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpcomingInvoiceQuery, UpcomingInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpcomingInvoiceQuery, UpcomingInvoiceQueryVariables>(UpcomingInvoiceDocument, options);
        }

export type UpcomingInvoiceQueryHookResult = ReturnType<typeof useUpcomingInvoiceQuery>;
export type UpcomingInvoiceLazyQueryHookResult = ReturnType<typeof useUpcomingInvoiceLazyQuery>;
export type UpcomingInvoiceQueryResult = Apollo.QueryResult<UpcomingInvoiceQuery, UpcomingInvoiceQueryVariables>;
export const ProductOptionsDocument = gql`
    query productOptions($filter: String) {
  products(where: {filter: $filter, types: [ADD_ON, EMBRYO_ANDRO, TREATMENT]}) {
    cpt
    id
    name
    type
    unitAmount
  }
}
    `;

/**
 * __useProductOptionsQuery__
 *
 * To run a query within a React component, call `useProductOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ProductOptionsQuery, ProductOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductOptionsQuery, ProductOptionsQueryVariables>(ProductOptionsDocument, options);
      }

export function useProductOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductOptionsQuery, ProductOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductOptionsQuery, ProductOptionsQueryVariables>(ProductOptionsDocument, options);
        }

export type ProductOptionsQueryHookResult = ReturnType<typeof useProductOptionsQuery>;
export type ProductOptionsLazyQueryHookResult = ReturnType<typeof useProductOptionsLazyQuery>;
export type ProductOptionsQueryResult = Apollo.QueryResult<ProductOptionsQuery, ProductOptionsQueryVariables>;
export const AcceptQuoteDocument = gql`
    mutation acceptQuote($quoteId: String!, $paymentMethodId: String!) {
  acceptQuote(quoteId: $quoteId, paymentMethodId: $paymentMethodId)
}
    `;
export type AcceptQuoteMutationFn = Apollo.MutationFunction<AcceptQuoteMutation, AcceptQuoteMutationVariables>;

/**
 * __useAcceptQuoteMutation__
 *
 * To run a mutation, you first call `useAcceptQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptQuoteMutation, { data, loading, error }] = useAcceptQuoteMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useAcceptQuoteMutation(baseOptions?: Apollo.MutationHookOptions<AcceptQuoteMutation, AcceptQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptQuoteMutation, AcceptQuoteMutationVariables>(AcceptQuoteDocument, options);
      }

export type AcceptQuoteMutationHookResult = ReturnType<typeof useAcceptQuoteMutation>;
export type AcceptQuoteMutationResult = Apollo.MutationResult<AcceptQuoteMutation>;
export type AcceptQuoteMutationOptions = Apollo.BaseMutationOptions<AcceptQuoteMutation, AcceptQuoteMutationVariables>;
export const DeclineQuoteDocument = gql`
    mutation declineQuote($quoteId: String!, $declineNote: String!) {
  declineQuote(quoteId: $quoteId, declineNote: $declineNote)
}
    `;
export type DeclineQuoteMutationFn = Apollo.MutationFunction<DeclineQuoteMutation, DeclineQuoteMutationVariables>;

/**
 * __useDeclineQuoteMutation__
 *
 * To run a mutation, you first call `useDeclineQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineQuoteMutation, { data, loading, error }] = useDeclineQuoteMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *      declineNote: // value for 'declineNote'
 *   },
 * });
 */
export function useDeclineQuoteMutation(baseOptions?: Apollo.MutationHookOptions<DeclineQuoteMutation, DeclineQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeclineQuoteMutation, DeclineQuoteMutationVariables>(DeclineQuoteDocument, options);
      }

export type DeclineQuoteMutationHookResult = ReturnType<typeof useDeclineQuoteMutation>;
export type DeclineQuoteMutationResult = Apollo.MutationResult<DeclineQuoteMutation>;
export type DeclineQuoteMutationOptions = Apollo.BaseMutationOptions<DeclineQuoteMutation, DeclineQuoteMutationVariables>;
export const LegalTermsDocument = gql`
    query LegalTerms {
  legalTerms {
    mppTermsOfService
    rolloverTermsOfService
  }
}
    `;

/**
 * __useLegalTermsQuery__
 *
 * To run a query within a React component, call `useLegalTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalTermsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLegalTermsQuery(baseOptions?: Apollo.QueryHookOptions<LegalTermsQuery, LegalTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LegalTermsQuery, LegalTermsQueryVariables>(LegalTermsDocument, options);
      }

export function useLegalTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LegalTermsQuery, LegalTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LegalTermsQuery, LegalTermsQueryVariables>(LegalTermsDocument, options);
        }

export type LegalTermsQueryHookResult = ReturnType<typeof useLegalTermsQuery>;
export type LegalTermsLazyQueryHookResult = ReturnType<typeof useLegalTermsLazyQuery>;
export type LegalTermsQueryResult = Apollo.QueryResult<LegalTermsQuery, LegalTermsQueryVariables>;
export const PatientQuoteDocument = gql`
    query patientQuote($where: QuoteWhereUniqueInput!) {
  patientQuote(where: $where) {
    id
    patient {
      id
      name
    }
    amountTotal
    quoteLineItems {
      total
      stripeProductId
      quantity
      product {
        name
      }
      finePrint {
        id
        description
      }
      hasDiscount
      discountAmount
      discountDescription
      discountType
      requiresPayInFull
    }
    previousQuoteLineItems {
      total
      stripeProductId
      quantity
      product {
        name
      }
    }
    contractLength
    amountTotal
    downPaymentTotal
    downPaymentExtra
    monthlyTotal
    status
    startDate
    endDate
    paidInFull
    paidInFullDiscount
    rolloverTotal
    migration {
      rolloverAmount
      description
      contractEnd
    }
    timeLeftInContract
  }
}
    `;

/**
 * __usePatientQuoteQuery__
 *
 * To run a query within a React component, call `usePatientQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientQuoteQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePatientQuoteQuery(baseOptions: Apollo.QueryHookOptions<PatientQuoteQuery, PatientQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientQuoteQuery, PatientQuoteQueryVariables>(PatientQuoteDocument, options);
      }

export function usePatientQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientQuoteQuery, PatientQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientQuoteQuery, PatientQuoteQueryVariables>(PatientQuoteDocument, options);
        }

export type PatientQuoteQueryHookResult = ReturnType<typeof usePatientQuoteQuery>;
export type PatientQuoteLazyQueryHookResult = ReturnType<typeof usePatientQuoteLazyQuery>;
export type PatientQuoteQueryResult = Apollo.QueryResult<PatientQuoteQuery, PatientQuoteQueryVariables>;
export const UpdateQuotePreviewDocument = gql`
    mutation updateQuotePreview($quoteId: String!, $contractLength: ContractLength!, $paidInFull: Boolean!, $paidOutstandingBalance: Boolean!, $downPaymentExtra: Float) {
  updateQuotePreview(
    quoteId: $quoteId
    contractLength: $contractLength
    paidInFull: $paidInFull
    paidOutstandingBalance: $paidOutstandingBalance
    downPaymentExtra: $downPaymentExtra
  )
}
    `;
export type UpdateQuotePreviewMutationFn = Apollo.MutationFunction<UpdateQuotePreviewMutation, UpdateQuotePreviewMutationVariables>;

/**
 * __useUpdateQuotePreviewMutation__
 *
 * To run a mutation, you first call `useUpdateQuotePreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuotePreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuotePreviewMutation, { data, loading, error }] = useUpdateQuotePreviewMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *      contractLength: // value for 'contractLength'
 *      paidInFull: // value for 'paidInFull'
 *      paidOutstandingBalance: // value for 'paidOutstandingBalance'
 *      downPaymentExtra: // value for 'downPaymentExtra'
 *   },
 * });
 */
export function useUpdateQuotePreviewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuotePreviewMutation, UpdateQuotePreviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuotePreviewMutation, UpdateQuotePreviewMutationVariables>(UpdateQuotePreviewDocument, options);
      }

export type UpdateQuotePreviewMutationHookResult = ReturnType<typeof useUpdateQuotePreviewMutation>;
export type UpdateQuotePreviewMutationResult = Apollo.MutationResult<UpdateQuotePreviewMutation>;
export type UpdateQuotePreviewMutationOptions = Apollo.BaseMutationOptions<UpdateQuotePreviewMutation, UpdateQuotePreviewMutationVariables>;
export const CreatePaymentIntentDocument = gql`
    mutation createPaymentIntent($quoteId: String!) {
  createPaymentIntent(quoteId: $quoteId) {
    id
    clientSecret
  }
}
    `;
export type CreatePaymentIntentMutationFn = Apollo.MutationFunction<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;

/**
 * __useCreatePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntentMutation, { data, loading, error }] = useCreatePaymentIntentMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useCreatePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>(CreatePaymentIntentDocument, options);
      }

export type CreatePaymentIntentMutationHookResult = ReturnType<typeof useCreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationResult = Apollo.MutationResult<CreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;
export const TestEmailDocument = gql`
    mutation testEmail {
  testEmail
}
    `;
export type TestEmailMutationFn = Apollo.MutationFunction<TestEmailMutation, TestEmailMutationVariables>;

/**
 * __useTestEmailMutation__
 *
 * To run a mutation, you first call `useTestEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testEmailMutation, { data, loading, error }] = useTestEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useTestEmailMutation(baseOptions?: Apollo.MutationHookOptions<TestEmailMutation, TestEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestEmailMutation, TestEmailMutationVariables>(TestEmailDocument, options);
      }

export type TestEmailMutationHookResult = ReturnType<typeof useTestEmailMutation>;
export type TestEmailMutationResult = Apollo.MutationResult<TestEmailMutation>;
export type TestEmailMutationOptions = Apollo.BaseMutationOptions<TestEmailMutation, TestEmailMutationVariables>;
export const VoidQuoteDocument = gql`
    mutation VoidQuote($quoteIds: [String!]) {
  voidQuote(quoteIds: $quoteIds)
}
    `;
export type VoidQuoteMutationFn = Apollo.MutationFunction<VoidQuoteMutation, VoidQuoteMutationVariables>;

/**
 * __useVoidQuoteMutation__
 *
 * To run a mutation, you first call `useVoidQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidQuoteMutation, { data, loading, error }] = useVoidQuoteMutation({
 *   variables: {
 *      quoteIds: // value for 'quoteIds'
 *   },
 * });
 */
export function useVoidQuoteMutation(baseOptions?: Apollo.MutationHookOptions<VoidQuoteMutation, VoidQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoidQuoteMutation, VoidQuoteMutationVariables>(VoidQuoteDocument, options);
      }

export type VoidQuoteMutationHookResult = ReturnType<typeof useVoidQuoteMutation>;
export type VoidQuoteMutationResult = Apollo.MutationResult<VoidQuoteMutation>;
export type VoidQuoteMutationOptions = Apollo.BaseMutationOptions<VoidQuoteMutation, VoidQuoteMutationVariables>;
export const CompleteQuoteDocument = gql`
    query completeQuote($where: QuoteWhereUniqueInput!) {
  completeQuote(where: $where) {
    id
    createdAt
    contractSigned
    status
    amountTotal
    downPaymentTotal
    depositPercent
    downPaymentExtra
    monthlyTotal
    contractLength
    contractSigned
    customerId
    locationId
    startDate
    paidInFull
    paidInFullDiscount
    endDate
    startDate
    rolloverTotal
    declineNote
    migration {
      rolloverAmount
      description
    }
    quoteLineItems {
      quoteId
      quantity
      total
      stripeProductId
      product {
        id
        name
      }
      hasDiscount
      discountType
      discountAmount
      discountDescription
      requiresPayInFull
      finePrint {
        description
      }
    }
  }
}
    `;

/**
 * __useCompleteQuoteQuery__
 *
 * To run a query within a React component, call `useCompleteQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompleteQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompleteQuoteQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCompleteQuoteQuery(baseOptions: Apollo.QueryHookOptions<CompleteQuoteQuery, CompleteQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompleteQuoteQuery, CompleteQuoteQueryVariables>(CompleteQuoteDocument, options);
      }

export function useCompleteQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompleteQuoteQuery, CompleteQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompleteQuoteQuery, CompleteQuoteQueryVariables>(CompleteQuoteDocument, options);
        }

export type CompleteQuoteQueryHookResult = ReturnType<typeof useCompleteQuoteQuery>;
export type CompleteQuoteLazyQueryHookResult = ReturnType<typeof useCompleteQuoteLazyQuery>;
export type CompleteQuoteQueryResult = Apollo.QueryResult<CompleteQuoteQuery, CompleteQuoteQueryVariables>;
export const LocationsDocument = gql`
    query locations {
  locations {
    id
    name
    stripeAccountId
    archived
  }
}
    `;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationsQuery(baseOptions?: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
      }

export function useLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
        }

export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = Apollo.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const CustomersDocument = gql`
    query customers($page: Int! = 1, $pageSize: Int! = 100, $filter: String = "", $includeArchived: Boolean = false) {
  customers(
    page: $page
    pageSize: $pageSize
    filter: $filter
    includeArchived: $includeArchived
  ) {
    pageInfo {
      nextPage
      totalPages
    }
    results {
      id
      eIvfId
      email
      name
      archived
    }
  }
}
    `;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
      }

export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }

export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const QuotesDocument = gql`
    query Quotes($quoteFilter: QuotesFilterInput, $page: Int, $pageSize: Int) {
  quotes(filter: $quoteFilter, page: $page, pageSize: $pageSize) {
    results {
      id
      createdAt
      contractSigned
      status
      patient {
        id
        email
        name
        eIvfId
      }
      createdBy {
        id
        name
        email
      }
    }
    pageInfo {
      page
      nextPage
      totalPages
      totalCount
      nextOffset
      prevOffset
      hasNextPage
      hasPrevPage
      lastPage
    }
  }
}
    `;

/**
 * __useQuotesQuery__
 *
 * To run a query within a React component, call `useQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotesQuery({
 *   variables: {
 *      quoteFilter: // value for 'quoteFilter'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useQuotesQuery(baseOptions?: Apollo.QueryHookOptions<QuotesQuery, QuotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuotesQuery, QuotesQueryVariables>(QuotesDocument, options);
      }

export function useQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuotesQuery, QuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuotesQuery, QuotesQueryVariables>(QuotesDocument, options);
        }

export type QuotesQueryHookResult = ReturnType<typeof useQuotesQuery>;
export type QuotesLazyQueryHookResult = ReturnType<typeof useQuotesLazyQuery>;
export type QuotesQueryResult = Apollo.QueryResult<QuotesQuery, QuotesQueryVariables>;
export const ServicesListDocument = gql`
    query servicesList {
  products {
    id
    name
    unitAmount
  }
}
    `;

/**
 * __useServicesListQuery__
 *
 * To run a query within a React component, call `useServicesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useServicesListQuery(baseOptions?: Apollo.QueryHookOptions<ServicesListQuery, ServicesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesListQuery, ServicesListQueryVariables>(ServicesListDocument, options);
      }

export function useServicesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesListQuery, ServicesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesListQuery, ServicesListQueryVariables>(ServicesListDocument, options);
        }

export type ServicesListQueryHookResult = ReturnType<typeof useServicesListQuery>;
export type ServicesListLazyQueryHookResult = ReturnType<typeof useServicesListLazyQuery>;
export type ServicesListQueryResult = Apollo.QueryResult<ServicesListQuery, ServicesListQueryVariables>;
export const CreateStripePortalSessionDocument = gql`
    mutation createStripePortalSession {
  createStripePortalSession
}
    `;
export type CreateStripePortalSessionMutationFn = Apollo.MutationFunction<CreateStripePortalSessionMutation, CreateStripePortalSessionMutationVariables>;

/**
 * __useCreateStripePortalSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripePortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripePortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripePortalSessionMutation, { data, loading, error }] = useCreateStripePortalSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripePortalSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripePortalSessionMutation, CreateStripePortalSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripePortalSessionMutation, CreateStripePortalSessionMutationVariables>(CreateStripePortalSessionDocument, options);
      }

export type CreateStripePortalSessionMutationHookResult = ReturnType<typeof useCreateStripePortalSessionMutation>;
export type CreateStripePortalSessionMutationResult = Apollo.MutationResult<CreateStripePortalSessionMutation>;
export type CreateStripePortalSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripePortalSessionMutation, CreateStripePortalSessionMutationVariables>;
export const PatientDetailsDocument = gql`
    query PatientDetails($id: ID!) {
  user(where: {id: $id}) {
    customer {
      name
    }
    quotes(excludeVoided: true) {
      id
      amountTotal
      downPaymentTotal
      downPaymentExtra
      monthlyTotal
      status
      declineNote
      contractSigned
      contractLength
      createdAt
      customerId
      locationId
      endDate
      startDate
      paidInFull
      paidInFullDiscount
      rolloverTotal
      migration {
        rolloverAmount
        description
      }
      quoteLineItems {
        quoteId
        quantity
        total
        stripeProductId
        product {
          id
          name
        }
        hasDiscount
        discountType
        discountAmount
        discountDescription
        finePrint {
          description
        }
      }
    }
    recentCharges {
      id
      object
      amount
      status
      created
    }
  }
}
    `;

/**
 * __usePatientDetailsQuery__
 *
 * To run a query within a React component, call `usePatientDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientDetailsQuery(baseOptions: Apollo.QueryHookOptions<PatientDetailsQuery, PatientDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientDetailsQuery, PatientDetailsQueryVariables>(PatientDetailsDocument, options);
      }

export function usePatientDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientDetailsQuery, PatientDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientDetailsQuery, PatientDetailsQueryVariables>(PatientDetailsDocument, options);
        }

export type PatientDetailsQueryHookResult = ReturnType<typeof usePatientDetailsQuery>;
export type PatientDetailsLazyQueryHookResult = ReturnType<typeof usePatientDetailsLazyQuery>;
export type PatientDetailsQueryResult = Apollo.QueryResult<PatientDetailsQuery, PatientDetailsQueryVariables>;
export const ContractLengthLimitDocument = gql`
    query ContractLengthLimit {
  contractLengthLimit
}
    `;

/**
 * __useContractLengthLimitQuery__
 *
 * To run a query within a React component, call `useContractLengthLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractLengthLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractLengthLimitQuery({
 *   variables: {
 *   },
 * });
 */
export function useContractLengthLimitQuery(baseOptions?: Apollo.QueryHookOptions<ContractLengthLimitQuery, ContractLengthLimitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractLengthLimitQuery, ContractLengthLimitQueryVariables>(ContractLengthLimitDocument, options);
      }

export function useContractLengthLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractLengthLimitQuery, ContractLengthLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractLengthLimitQuery, ContractLengthLimitQueryVariables>(ContractLengthLimitDocument, options);
        }

export type ContractLengthLimitQueryHookResult = ReturnType<typeof useContractLengthLimitQuery>;
export type ContractLengthLimitLazyQueryHookResult = ReturnType<typeof useContractLengthLimitLazyQuery>;
export type ContractLengthLimitQueryResult = Apollo.QueryResult<ContractLengthLimitQuery, ContractLengthLimitQueryVariables>;